@import '../../Style/variables';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

@font-face {
	font-family: "DolceVitaHeavy";
 	src: url("../../Style/DolceVitaHeavyBold.woff2") format("woff2");
 	font-display: swap;
}

@media (min-width: 1400px) {
	.container, .container-lg, .container-md, .container-sm, .container-xl {
	    max-width: $main-container-width;
	}
}

body {
	font-family: 'Roboto', sans-serif;
	font-size: $page-content-size;
	line-height: 20px;
	background: $main-background;
	a {
		color: $soft-black;

		&:hover {
			color: $soft-black;
		}
	}
}