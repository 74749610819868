// COLOR //
$heart-card: #9E0E40;
$spade-card: #1F1F1F;
$diamond-card: #040B64;
$club-card: #004E00;
$soft-black: #333333;
$soft-grey: rgba(51, 51, 51, 0.5);
$border-grey: #e4e4e4;
$grey: #fafafa;
$header-background: #ffffff;
$main-background: $grey;
$grey-hover: #808080;


// LAYOUT //
$main-container-width: 1400px;
$header-height: 50px;

// FONT // 
$page-title-size: 3em;
$page-subtitle-size: 1.5em;
$big-content-size: 1.1em;
$page-content-size: 0.85em;

$transition-timing: 0.3s ease;

