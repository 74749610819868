@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face{font-family:"DolceVitaHeavy";src:url(/static/media/DolceVitaHeavyBold.4a5fd35f.woff2) format("woff2");font-display:swap}@media (min-width: 1400px){.container,.container-lg,.container-md,.container-sm,.container-xl{max-width:1400px}}body{font-family:'Roboto', sans-serif;font-size:.85em;line-height:20px;background:#fafafa}body a{color:#333}body a:hover{color:#333}

